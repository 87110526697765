import { PurchaseHistory } from '@torihada-inc/miniapps/dist/User'
export enum PURCHASE_METHOD {
  CARD = 'card',
  CVS = 'convenience',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  PAY_PAY = 'pay_pay',
}

export type OrderRes = {
  fee: number
  delivery_cost: number
  price: number
  total: number
  cvs_amount_exceed: boolean
}

export type CvsData = {
  cvsName: string
  name: string
  hurigana: string
  phoneNumber: string
}

export type CvsResult = {
  conf_no: string
  convenience: string
  payment_term: string
  receipt_no: string
  receipt_url: string
  status: string
}

export type CvsMetaData = {
  name: string
  hurigana: string
  phoneNumber: string
}

export type CvsFeeData = {
  fee: number
  less_amount: number
  more_amount: number
}

export interface PurchaseHistoryWithTip extends PurchaseHistory {
  tipId: number
  viewerUrl?: string
  orderId?: string | null
}

export type UnreadMsg = {
  has_dfl: boolean // seller(クリエイター)がデジタルファンレターを開設しているか
  unread_letter_count: number //  未読のレター数、has_dflがfalseの場合は常に0
}

import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import { SalesDetail, ShopSalesHistory } from '@/types/ShopSalesHistory'
import { Colors } from '@/fanmeUi/constant'
import { useShopApi } from '@/libs/useShopApi'
import { NoItemContainer } from '@/components/molecules/MyMenu/NotificationList/index.style'
import {
  FieldAppIcon,
  FieldContainer,
  FieldData,
  FieldHistoryData,
  FieldHistoryTitle,
  FieldIcon,
  FieldItemContainer,
  FieldItemData,
  FieldTip,
  FieldValue,
  HistoryItemContainer,
  IconData,
  IconName,
  ItemTitele,
} from '@/components/molecules/MyMenu/MoneyManagementHistory/index.style'
import { formatDate } from '@/libs/utils'
import ARROW_RIGHT from '@/assets/img/icons/Arrow_Right.svg'
import { InfiniteScrollContainer } from '@/components/templates/InfiniteScroll'
import BoundLoadingIcon from '@/components/atoms/LoadingIcon/BoundLoadingIcon'

interface Props {
  setVisibleDetails: (visible: boolean) => void
  setTableName: (tableName: string) => void
  setTableId: (tableId: string) => void
  setShopSalesHistoryDetail: (shopSalesHistoryDetail: SalesDetail) => void
}

const ShopMoneyManagementHistory = ({
  setVisibleDetails,
  setTableName,
  setTableId,
  setShopSalesHistoryDetail,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [salesHistories, setSalesHistories] = useState<ShopSalesHistory[]>([])
  const [offset, setOffset] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const shopApi = useShopApi()
  const limit = 20

  // ショップの売上リストの取得
  const getSalesHistories = async (offset: number) => {
    setLoading(true)
    try {
      const data = await shopApi.get(`/shops/current/sales-history?$top=${limit}&$skip=${offset}`)
      const items = data.data.sales_histories as ShopSalesHistory[]
      let newItems: ShopSalesHistory[] = []
      if (offset === 0) {
        newItems = items
      } else {
        newItems = [...(items || []), ...items]
      }
      setSalesHistories(newItems)
      setHasMore(items.length > limit) // チップを含む分多くなる場合がある
    } catch (error) {
      console.error('Error fetching purchased items:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getSalesHistories(0)
  }, [])

  const handleLoadMore = async () => {
    if (hasMore && !loading) {
      const newOffset = offset + limit
      setOffset(newOffset)
      getSalesHistories(newOffset)
    }
  }

  const toNext = (item: ShopSalesHistory) => {
    setTableId(item.order_id.toString())
    setShopSalesHistoryDetail(item.sales_detail)
    setTableName('ShopSalesHistory')
    setVisibleDetails(true)
  }

  if (salesHistories && salesHistories.length === 0) {
    return (
      <NoItemContainer>
        <div style={{ color: Colors.LIGHT_GRAY }}>売上がありません</div>
      </NoItemContainer>
    )
  }

  return (
    <InfiniteScrollContainer fetchMore={handleLoadMore}>
      {salesHistories &&
        salesHistories.map((item, i) => (
          <HistoryItemContainer key={`${item.order_id}-${i}`} onClick={() => toNext(item)}>
            <FieldItemContainer>
              <FieldHistoryTitle>
                <IconData>
                  <FieldIcon width="20" height="20" src={item.purchaser.icon_uri} />
                  <ItemTitele>{item.purchaser.name}</ItemTitele>
                </IconData>
                <FieldData>{formatDate(item.soled_at, 0)}</FieldData>
              </FieldHistoryTitle>
              <FieldContainer>
                <FieldValue>
                  ¥{numeral(item.amount + (item.tip_amount || 0)).format('0,0')}
                </FieldValue>
                {item.tip_amount > 0 && (
                  <FieldTip>{`(内チップ:¥${numeral(item.tip_amount).format('0,0')})`}</FieldTip>
                )}
              </FieldContainer>
            </FieldItemContainer>
            <ARROW_RIGHT width={16} height={16} />
          </HistoryItemContainer>
        ))}
      {/* ローディングアイコン */}
      {loading && (
        <div className="mt-16">
          <BoundLoadingIcon backendColor={Colors.BLACK} />
        </div>
      )}
    </InfiniteScrollContainer>
  )
}

export default ShopMoneyManagementHistory

import React from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledContainer = styled.div`
  position: absolute;
  max-width: 480px;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  left: 0;
  top: 0;
  z-index: 10002;
  padding: 0;
`

const StyledDialogBG = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`

const StyledDialogContainer = styled.div`
  width: calc(100% - 80px);
  max-width: 328px;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colors.WHITE};
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
`

const StyledCardInfo = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  .detail {
    display: grid;
    align-items: center;
    margin-left: 12px;
    .numbers {
      color: ${Colors.BLACK};
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }
    .date {
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      color: ${Colors.GRAY};
    }
  }
`

const StyledCardAction = styled.div<{ color?: string }>`
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  border-top: 1px solid ${Colors.VERY_LIGHT_GRAY};
  color: ${props => props.color || Colors.PRIMARY_GRAY};
  cursor: pointer;
`

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 248px;
  width: 100%;
  height: 48px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: ${Colors.WHITE};
  background: ${Colors.PRIMARY_GRAY};
  border-radius: 24px;
  margin: 24px auto;
`

interface Props {
  visible: boolean
  cardBrandType: any
  cardNumber: string
  cardExpiresAt: string
  selected?: boolean
  onSetUseCard: () => void
  onUpdate: () => void
  onDelete: () => void
  onClose: () => void
}

const CardSetup = ({
  visible,
  cardBrandType,
  cardNumber,
  cardExpiresAt,
  selected,
  onSetUseCard,
  onUpdate,
  onDelete,
  onClose,
}: Props) => {
  if (!visible) return <></>
  return (
    <>
      <StyledContainer>
        <StyledDialogBG onClick={onClose} />
        <StyledDialogContainer>
          <StyledDialog>
            <StyledCardInfo>
              {cardBrandType && cardBrandType.icon}
              <div className="detail">
                <div className="numbers">
                  {cardBrandType && cardBrandType.name}
                  {cardNumber}
                </div>
                <div className="date">有効期限: {cardExpiresAt}</div>
              </div>
            </StyledCardInfo>
            {!selected && (
              <StyledCardAction onClick={onSetUseCard}>支払いに利用する</StyledCardAction>
            )}
            <StyledCardAction onClick={onUpdate}>カード情報を更新</StyledCardAction>
            <StyledCardAction onClick={onDelete} color={Colors.RED}>
              カードを削除
            </StyledCardAction>
          </StyledDialog>
          <StyledCloseButton onClick={onClose}>キャンセル</StyledCloseButton>
        </StyledDialogContainer>
      </StyledContainer>
    </>
  )
}

export default CardSetup

import React, { useEffect, useState } from 'react'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { PurchaseHistoryWithTip } from '@/types/Purchase'
import Tabs from '@/components/atoms/Tabs'
import PaymentContent from '../PaymentContent'
import BrowseMiniappPaymentHistory from './BrowseMiniappPaymentHistory'
import BrowseShopPaymentHistory from './BrowseShopPaymentHistory'

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
}

const BrowsePaymentHistory = ({ accountId, visible, onClose }: Props) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [purchaseItem, setPurchaseItem] = useState<PurchaseHistoryWithTip>()
  const [visiblePopup, setVisiblePopup] = useState(false)

  useEffect(() => {
    if (visible === false) setVisiblePopup(false)
  }, [visible])

  const renderContent = () => (
    <>
      <Tabs
        current={tabIndex}
        tabs={['ショップ', '旧ショップ']}
        onChange={index => setTabIndex(index)}
      />
      {tabIndex === 0 ? (
        <BrowseShopPaymentHistory
          setPurchaseItem={setPurchaseItem}
          setVisiblePopup={setVisiblePopup}
        />
      ) : (
        <BrowseMiniappPaymentHistory
          accountId={accountId}
          setPurchaseItem={setPurchaseItem}
          setVisiblePopup={setVisiblePopup}
        />
      )}
    </>
  )

  return (
    <>
      <FullModal
        header={<FullModalHeader title="購入履歴" onBack={onClose} />}
        content={renderContent()}
        visible={visible}
        zIndex={10001}
        bodyPadding="0"
      />

      {visiblePopup && (
        <PaymentContent
          type={tabIndex === 0 ? 'shop' : 'miniapp'}
          visible={visiblePopup}
          onClose={() => setVisiblePopup(false)}
          item={purchaseItem}
        />
      )}
    </>
  )
}

export default BrowsePaymentHistory

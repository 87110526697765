import numeral from 'numeral'
import React from 'react'
import { SellerHistoryAccountActivityResponse } from '@torihada-inc/miniapps/dist/repositories/user/SellerAccountHistoryRepository'
import {
  ManagementAmount,
  ManagementAmountContainer,
  ManagementAmountDetail,
  ManagementAmountTotal,
  ManagementAmountVal,
  ManagementDetailData,
  ManagementDetailTitle,
  ManagementErrFee,
  ManagementTotalAmount,
  StyledDetailContainer,
} from '@/components/molecules/MyMenu/MoneyManagementDetail/index.style'
import { formatDateJP } from '@/libs/utils'

interface Props {
  activityData?: SellerHistoryAccountActivityResponse
}

const status = {
  LAPSE: '失効',
  RECEIVING_COMPLETED: '受取完了',
  RECEIVING_ERROR: '受取エラー',
}

const AccountActivitiesDetail = ({ activityData }: Props) => {
  return (
    <>
      <StyledDetailContainer>
        <ManagementDetailTitle>受取申請日</ManagementDetailTitle>
        <ManagementDetailData>{formatDateJP(activityData?.created_at, 9)}</ManagementDetailData>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>受取方法</ManagementDetailTitle>
        <ManagementDetailData>銀行振込</ManagementDetailData>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>ステータス</ManagementDetailTitle>
        <ManagementDetailData isError={activityData?.status !== status.RECEIVING_COMPLETED}>
          {activityData?.status}
        </ManagementDetailData>
      </StyledDetailContainer>

      {activityData?.status === status.LAPSE ? ( //失効の場合
        <>
          <StyledDetailContainer>
            <ManagementDetailTitle>失効日</ManagementDetailTitle>
            <ManagementDetailData>{formatDateJP(activityData?.updated_at, 9)}</ManagementDetailData>
          </StyledDetailContainer>

          <StyledDetailContainer>
            <ManagementDetailTitle>失効金額</ManagementDetailTitle>
            <ManagementErrFee>
              -￥{numeral(activityData.expired_sales_amount).format('0,0')}
            </ManagementErrFee>
          </StyledDetailContainer>
        </>
      ) : (
        activityData?.status === status.RECEIVING_ERROR ||
        (activityData?.status === status.RECEIVING_COMPLETED && (
          <>
            {activityData?.status === status.RECEIVING_COMPLETED && (
              <StyledDetailContainer>
                <ManagementDetailTitle>振込金額</ManagementDetailTitle>

                <ManagementAmountContainer>
                  <ManagementAmountTotal>
                    <ManagementAmount>合計</ManagementAmount>
                    <ManagementTotalAmount>
                      ¥{numeral(activityData?.received_sales_amount).format('0,0')}
                    </ManagementTotalAmount>
                  </ManagementAmountTotal>

                  <ManagementAmountDetail>
                    <ManagementAmount isGray>売上金</ManagementAmount>
                    <ManagementAmountVal>
                      ¥{numeral(activityData?.transfer_amount).format('0,0')}
                    </ManagementAmountVal>
                  </ManagementAmountDetail>

                  <ManagementAmountDetail>
                    <ManagementAmount isGray>振込手数料</ManagementAmount>
                    <ManagementAmountVal isRed>
                      -¥{numeral(activityData?.transfer_fee).format('0,0')}
                    </ManagementAmountVal>
                  </ManagementAmountDetail>
                </ManagementAmountContainer>
              </StyledDetailContainer>
            )}

            <StyledDetailContainer>
              <ManagementDetailTitle>振込日</ManagementDetailTitle>
              <ManagementDetailData>
                {formatDateJP(activityData?.updated_at, 9)}
              </ManagementDetailData>
            </StyledDetailContainer>

            {activityData?.status === status.RECEIVING_ERROR && (
              <StyledDetailContainer>
                <ManagementDetailTitle>振込エラー手数料</ManagementDetailTitle>
                <ManagementErrFee>-¥220</ManagementErrFee>
              </StyledDetailContainer>
            )}
          </>
        ))
      )}
    </>
  )
}

export default AccountActivitiesDetail

import numeral from 'numeral'
import React from 'react'
import {
  AppName,
  AppWapper,
  FieldAppIcon,
  ManagementAmount,
  ManagementAmountContainer,
  ManagementAmountDetail,
  ManagementAmountTotal,
  ManagementAmountVal,
  ManagementDetailData,
  ManagementDetailTitle,
  ManagementTotalAmount,
  SellerAppName,
  StyledDetailContainer,
} from '@/components/molecules/MyMenu/MoneyManagementDetail/index.style'
import { formatDateJP } from '@/libs/utils'
import { SellerHistoryAccountTransactionResponseWithTip } from '@/types/MiniappSellerHistory'

interface Props {
  transactionData?: SellerHistoryAccountTransactionResponseWithTip[]
  currentItemDetail?: SellerHistoryAccountTransactionResponseWithTip
  goodsPrice: number
  tipPrice: number
}

const status = {
  SUCCESS: 'success',
}

const MiniappTransactionDetail = ({
  transactionData,
  currentItemDetail,
  goodsPrice,
  tipPrice,
}: Props) => {
  return (
    <>
      <StyledDetailContainer>
        <ManagementDetailTitle>販売アプリ</ManagementDetailTitle>
        <AppWapper className="max-w-[60%]">
          <AppName>{currentItemDetail?.app_name}</AppName>
          <FieldAppIcon width="40" height="40" src={currentItemDetail?.app_icon} />
        </AppWapper>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>商品名</ManagementDetailTitle>
        <div className="max-w-[60%]">
          {transactionData?.map((item, index) => {
            if (item.tip_id) return null
            return (
              <AppWapper key={index}>
                <div>
                  <SellerAppName>{item?.seller_app_name}</SellerAppName>
                  <AppName>{item?.seller_app_item_name}</AppName>
                </div>
                <FieldAppIcon width="40" height="40" src={item?.seller_app_item_icon} />
              </AppWapper>
            )
          })}
        </div>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>ステータス</ManagementDetailTitle>
        <ManagementDetailData className="font-sansJP">
          {currentItemDetail?.status === status.SUCCESS ? '購入済み' : '購入失敗'}
        </ManagementDetailData>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>購入日</ManagementDetailTitle>
        <ManagementDetailData fontSize={13} className="font-Montserrat">
          {formatDateJP(currentItemDetail?.updated_at, 9)}
        </ManagementDetailData>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>売上金</ManagementDetailTitle>
        <ManagementAmountContainer>
          <ManagementAmountTotal>
            <ManagementAmount>利益</ManagementAmount>
            <ManagementTotalAmount>
              ¥{numeral(currentItemDetail?.seller_amount).format('0,0')}
              <span>(税込)</span>
            </ManagementTotalAmount>
          </ManagementAmountTotal>
          <ManagementAmountDetail>
            <ManagementAmount isGray>商品価格</ManagementAmount>
            <ManagementAmountVal>¥{numeral(goodsPrice).format('0,0')}</ManagementAmountVal>
          </ManagementAmountDetail>
          {tipPrice > 0 && (
            <ManagementAmountDetail>
              <ManagementAmount isGray>チップ</ManagementAmount>
              <ManagementAmountVal>¥{numeral(tipPrice).format('0,0')}</ManagementAmountVal>
            </ManagementAmountDetail>
          )}
          <ManagementAmountDetail>
            <ManagementAmount isGray>販売手数料</ManagementAmount>
            <ManagementAmountVal isRed>
              -¥{numeral(currentItemDetail?.sales_fee).format('0,0')}
            </ManagementAmountVal>
          </ManagementAmountDetail>
        </ManagementAmountContainer>
      </StyledDetailContainer>
    </>
  )
}

export default MiniappTransactionDetail

import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Colors } from '@/constants/styles/color'
import Settings from '@/assets/img/icons/Settings.svg'
import ArrowBack from '@/assets/img/icons/Arrow_Back.svg'
import HalfModal from '@/components/atoms/Dialog/HalfModal'
import HalfModalHeader from '@/components/molecules/Header/HalfModalHeader'
import EnablePushNotification from '@/components/molecules/EnablePushNotification'
import { getCurrentSetting, updateSettingNotification } from '@/pages/api/creator'

type Props = {
  title?: string
  onAction: () => void
  onClose: () => void
}

const ModalHeaderWithSetting = ({ title, onClose }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [initialEnabled, setInitialEnabled] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCurrentSetting()
      setEnabled(data.enabled || false)
      setInitialEnabled(data.enabled || false) // 初期状態を記録
    }
    fetchData()
  }, [])

  const onOpen = () => {
    setIsModalVisible(true)
  }

  const onSave = async () => {
    await updateSettingNotification(enabled)
    setIsModalVisible(false)
    setInitialEnabled(enabled)
  }

  const onBack = () => {
    setIsModalVisible(false)
    setEnabled(initialEnabled)
  }

  const renderHeader = () => (
    <HalfModalHeader
      title="通知設定"
      background={Colors.WHITE}
      textColor={Colors.BLACK}
      onBack={onBack}
    />
  )

  const renderContent = () => {
    return (
      <div className="relative h-full">
        <EnablePushNotification setEnabled={setEnabled} enabled={enabled} />
        <div className="w-full p-4 flex justify-center">
          <button
            className={clsx(
              `rounded-3xl font-bold leading-[14px] flex items-center justify-center text-white h-12 w-full max-w-[328px] my-6 transition-all`,
              enabled === initialEnabled ? 'bg-PALE_GRAY' : 'bg-BLACK',
            )}
            onClick={onSave}
            disabled={enabled === initialEnabled}
          >
            保存する
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-fanme-black w-full h-[56px] flex justify-between items-center px-4">
      <ArrowBack fill={Colors.WHITE} onClick={onClose} />
      <div className="text-base font-medium text-white leading-[14px]">{title}</div>
      <Settings className="size-5 text-white" onClick={onOpen} />
      {isModalVisible && <HalfModal header={renderHeader()} content={renderContent()} top={46} />}
    </div>
  )
}

export default ModalHeaderWithSetting

import React from 'react'
import ArrowForward from '@/assets/img/icons/Arrow_Forward.svg'
import Reject from '@/assets/img/icons/Reject.svg'
import { Colors } from '@/constants/styles/color'
import { StyledMiniAppEndNoticeContainer, StyledMiniAppEndNoticeContent } from './index.style'

const MINI_APP_END_NOTICE_URL = 'https://media.fanme.link/1793/'

const MiniAppEndNotice: React.FC = () => {
  return (
    <StyledMiniAppEndNoticeContainer href={MINI_APP_END_NOTICE_URL} target="_blank">
      <StyledMiniAppEndNoticeContent>
        <Reject style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        【重要】一部ミニアプリ終了のお知らせ
      </StyledMiniAppEndNoticeContent>
      <ArrowForward
        style={{
          width: '6px',
          height: '12px',
          marginLeft: '8px',
          fill: Colors.PURE_BLACK,
          alignSelf: 'center',
        }}
      />
    </StyledMiniAppEndNoticeContainer>
  )
}

export default MiniAppEndNotice

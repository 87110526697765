import numeral from 'numeral'
import React from 'react'
import { SalesDetail } from '@/types/ShopSalesHistory'
import {
  AppName,
  AppWapper,
  FieldAppIcon,
  ManagementAmount,
  ManagementAmountContainer,
  ManagementAmountDetail,
  ManagementAmountTotal,
  ManagementAmountVal,
  ManagementDetailData,
  ManagementDetailTitle,
  ManagementTotalAmount,
  StyledDetailContainer,
} from '@/components/molecules/MyMenu/MoneyManagementDetail/index.style'
import { formatDateJP } from '@/libs/utils'

interface Props {
  shopSalesHistoryDetail: SalesDetail
}

const ShopSalesHistoryDetail = ({ shopSalesHistoryDetail }: Props) => {
  return (
    <>
      <StyledDetailContainer>
        <ManagementDetailTitle>商品名</ManagementDetailTitle>
        <div className="max-w-[60%]">
          {shopSalesHistoryDetail.soled_items?.map((item, index) => {
            return (
              <AppWapper key={index}>
                <div>
                  <AppName>{item.item_name}</AppName>
                </div>
                <FieldAppIcon width="40" height="40" src={item.item_thumbnail_uri} />
              </AppWapper>
            )
          })}
        </div>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>ステータス</ManagementDetailTitle>
        <ManagementDetailData className="font-sansJP">購入済み</ManagementDetailData>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>購入日</ManagementDetailTitle>
        <ManagementDetailData fontSize={13} className="font-Montserrat">
          {formatDateJP(shopSalesHistoryDetail.soled_at, 0)}
        </ManagementDetailData>
      </StyledDetailContainer>

      <StyledDetailContainer>
        <ManagementDetailTitle>売上金</ManagementDetailTitle>
        <ManagementAmountContainer>
          <ManagementAmountTotal>
            <ManagementAmount>利益</ManagementAmount>
            <ManagementTotalAmount>
              ¥{numeral(shopSalesHistoryDetail.sales.profit).format('0,0')}
              <span>(税込)</span>
            </ManagementTotalAmount>
          </ManagementAmountTotal>
          <ManagementAmountDetail>
            <ManagementAmount isGray>商品価格</ManagementAmount>
            <ManagementAmountVal>
              ¥{numeral(shopSalesHistoryDetail.sales.item_amount).format('0,0')}
            </ManagementAmountVal>
          </ManagementAmountDetail>
          {shopSalesHistoryDetail.sales.tip_amount > 0 && (
            <ManagementAmountDetail>
              <ManagementAmount isGray>チップ</ManagementAmount>
              <ManagementAmountVal>
                ¥{numeral(shopSalesHistoryDetail.sales.tip_amount).format('0,0')}
              </ManagementAmountVal>
            </ManagementAmountDetail>
          )}
          <ManagementAmountDetail>
            <ManagementAmount isGray>販売手数料</ManagementAmount>
            <ManagementAmountVal isRed>
              -¥{numeral(shopSalesHistoryDetail.sales.fee).format('0,0')}
            </ManagementAmountVal>
          </ManagementAmountDetail>
        </ManagementAmountContainer>
      </StyledDetailContainer>
    </>
  )
}

export default ShopSalesHistoryDetail

import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledHistoryItem = styled.div`
  display: flex;
  padding: 16px 0;
  margin: 0 16px;
  width: calc(100% - 32px);
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
  max-width: 100%;
`

const StyledHistoryItemGrid = styled.div`
  display: grid;
  flex-direction: column;
  max-width: 60%;
  padding: 0 12px;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
`

const StyledItemImage = styled.img`
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 8px;
`

const StyledItemNonImage = styled.div`
  width: 72px;
  height: 72px;
  background: ${Colors.BK_GRAY};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledItemName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.BLACK};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100%;
`

const StyledItemPrice = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: ${Colors.BLACK};
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 24px;
  display: flex;
  align-items: center;
`

const StyledItemPurchasedDate = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 1px;
  color: ${Colors.GRAY};
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const StyledOrderNumber = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 1px;
  color: ${Colors.GRAY};
  text-align: left;
`

export {
  StyledHistoryItem,
  StyledHistoryItemGrid,
  StyledItemImage,
  StyledItemNonImage,
  StyledItemName,
  StyledItemPrice,
  StyledItemPurchasedDate,
  StyledOrderNumber,
}

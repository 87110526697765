import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { PurchasedItem } from '@/types/PurchasedItem'
import Arrow_Right from '@/assets/img/icons/Arrow_Right.svg'
import { Colors } from '@/constants/styles/color'
import MylibraryIcon from '@/assets/img/icons/Library.svg'
import { useShopApi } from '@/libs/useShopApi'
import BoundLoadingIcon from '@/components/atoms/LoadingIcon/BoundLoadingIcon'
import { InfiniteScrollContainer } from '@/components/templates/InfiniteScroll'
import { NoItemContainer } from '@/components/molecules/MyMenu/NotificationList/index.style'
import { PurchaseHistoryWithTip } from '@/types/Purchase'

interface Props {
  setPurchaseItem: React.Dispatch<React.SetStateAction<PurchaseHistoryWithTip | undefined>>
  setVisiblePopup: React.Dispatch<React.SetStateAction<boolean>>
}
const BrowseShopPaymentHistory = ({ setPurchaseItem, setVisiblePopup }: Props) => {
  const [purchasedItems, setPurchasedItems] = useState<PurchasedItem[] | null>(null)
  const [loading, setLoading] = useState(true)
  const limit = 20
  const [offset, setOffset] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const shopApi = useShopApi()

  // 購入済み商品の取得
  const getPurchasedItems = async (offset: number) => {
    setLoading(true)
    try {
      const data = await shopApi.get(
        `/shops/current/purchased-items?include_tip=true&$top=${limit}&$skip=${offset}`,
      )
      const items = data.data.purchased_items as PurchasedItem[]
      let newItems: PurchasedItem[] = []
      if (offset === 0) {
        newItems = items
      } else {
        newItems = [...(purchasedItems || []), ...items]
      }
      setPurchasedItems(newItems)
      setHasMore(items.length > limit) // チップを含む分多くなる場合がある
    } catch (error) {
      console.error('Error fetching purchased items:', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getPurchasedItems(0)
  }, [])

  const handleLoadMore = async () => {
    if (hasMore && !loading) {
      const newOffset = offset + limit
      setOffset(newOffset)
      getPurchasedItems(newOffset)
    }
  }

  // 商品のクリックアクション
  const toNext = (item: PurchasedItem) => {
    const purchaseHistoryWithTip: PurchaseHistoryWithTip = {
      id: item.id,
      tipId: item.tip_id,
      sellerUserId: item.creator_uid,
      purchasedAt: item.purchased_at ? dayjs(item.purchased_at) : dayjs(),
      appId: 0,
      appItemId: 0,
      sellerAppId: 0,
      sellerAppItemId: 0,
      sellerAppName: '',
      sellerAppItemName: item.tip_id ? '' : item.name,
      price: item.price,
      sellerAppItemDescription: item.description,
      sellerAppItemIconUrl: item.thumbnail_uri,
      viewerUrl: item.viewer_url,
      orderId: item.order_number || String(item.order_id) || '-',
    }
    setPurchaseItem(purchaseHistoryWithTip)
    setVisiblePopup(true)
  }

  // build
  if (purchasedItems && purchasedItems.length === 0) {
    return (
      <>
        <NoItemContainer>
          <MylibraryIcon width={64} height={64} fill={Colors.LIGHT_GRAY} />
          <div style={{ color: Colors.LIGHT_GRAY }}>購入履歴がありません</div>
        </NoItemContainer>
      </>
    )
  }

  return (
    <>
      <div>
        <InfiniteScrollContainer fetchMore={handleLoadMore}>
          {purchasedItems &&
            purchasedItems.map(item => (
              <div
                key={item.id <= 0 ? `tip_${item.tip_id}` : `item_${item.id}`}
                onClick={() => toNext(item)}
                className="flex items-center p-4 border-b border-VERY_LIGHT_GRAY cursor-pointer"
              >
                {/* 商品イメージ */}
                {item.thumbnail_uri ? (
                  <img
                    src={item.thumbnail_uri}
                    alt={item.name}
                    className="w-18 h-18 rounded-lg object-cover flex-shrink-0"
                  />
                ) : (
                  <div className="w-18"></div>
                )}
                <div className="flex-grow px-4 text-left">
                  {/* 商品名称 */}
                  <div className="text-BLACK text-md mt-1">{item.name}</div>
                  <div className="text-BLACK text-md font-semibold mt-1">
                    ¥{item.price.toLocaleString()}
                  </div>
                  {/* 購入日時 */}
                  <div className="text-LIGHT_GRAY text-left mt-1 text-[11px] font-normal leading-[10px] tracking-[1px]">
                    {item.purchased_at}
                  </div>
                  {!item.tip_id && (
                    <div className="text-LIGHT_GRAY text-left mt-1 text-[11px] font-normal leading-[10px] tracking-[1px]">
                      注文番号：{item.order_number || '-'}
                    </div>
                  )}
                </div>
                {/* 矢印 */}
                <div className="flex-shrink-0">
                  <Arrow_Right className="w-4.5 h-4.5" />
                </div>
              </div>
            ))}
          {/* ローディングアイコン */}
          {loading && (
            <div className="mt-16">
              <BoundLoadingIcon backendColor={Colors.BLACK} />
            </div>
          )}
        </InfiniteScrollContainer>
      </div>
    </>
  )
}

export default BrowseShopPaymentHistory

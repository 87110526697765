import React, { useRef, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import getConfig from 'next/config'
import { putForm } from '@/pages/api/base'
import EncourageShop from '@/assets/img/home/img_EncourageShop.webp'
import { Colors } from '@/constants/styles/color'
import { useFanmeBackendGet } from '@/libs/fanme_backend'

const StyledPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledCard = styled.div`
  padding: 8px 8px 16px;
  background: white;
  border-radius: 16px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  margin: 0 auto;

  @media (min-width: 480px) {
    max-width: 440px;
  }
`

const StyledPopupImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 480px;
`
const StyledText = styled.p`
  text-align: center;
  margin: 27px 0;
  color: #f68720;
  font-size: 15px;
  font-weight: 700;
`

const StyledButton = styled.button`
  background: ${Colors.BLACK};
  width: 80%;
  margin: 16px auto 0;
  max-height: 72px;
  padding: 12px;
  border-radius: 24px;
  border: none;
  color: ${Colors.WHITE};
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButtonText = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: normal;
  font-size: 16px;
`

const StyledCloseButton = styled.button`
  width: 88px;
  height: 40px;
  margin: 24px auto;
  font-size: 12px;
  padding: 12px 16px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  color: ${Colors.BLACK};
  background-clip: content-box, border-box;
  background: ${Colors.WHITE};
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25), -1px -1px 8px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${Colors.WHITE};
  }
`

const ShopEncouragementPopup = () => {
  const SHOP_ENCOURAGEMENT_FLAG_KEY = 'shop_encouragement'
  const { publicRuntimeConfig } = getConfig()
  const [visible, setVisible] = useState(false)
  const { data: visibleTutorialData } = useFanmeBackendGet<any>(
    `/fanme/api/creators/self/user_tutorials/${SHOP_ENCOURAGEMENT_FLAG_KEY}`,
  )

  useEffect(() => {
    if (visibleTutorialData) {
      if (visibleTutorialData.display_flg) {
        setVisible(true)
        markAsDisplayed()
      }
    }
  }, [visibleTutorialData])

  // StyledCardの外側をクリックしたら閉じる
  const refCard = useRef<HTMLDivElement>(null)
  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    if (refCard.current && !refCard.current.contains(event.target as Node)) {
      handleClose()
    }
  }

  const navigateToShop = async () => {
    window.open(publicRuntimeConfig.SHOP_URL, '_blank')
    setVisible(false)
  }

  const handleClose = () => {
    setVisible(false)
  }

  const markAsDisplayed = async () => {
    const formData = new FormData()
    formData.append('display_flg', 'false')
    await putForm(`/creators/self/user_tutorials/${SHOP_ENCOURAGEMENT_FLAG_KEY}`, formData)
  }

  if (!visible) return null
  return (
    <StyledPopupWrapper onClick={handleClickOutside}>
      <StyledPopupContent>
        <StyledCard ref={refCard}>
          <div className="rounded-[14px] overflow-hidden">
            <StyledPopupImage
              src={EncourageShop.src}
              alt={'SHOPを試してみる'}
              onClick={navigateToShop}
            />
          </div>
          <StyledText>出品がしやすくなりました！</StyledText>
          <StyledButton onClick={navigateToShop}>
            <StyledButtonText>試してみる</StyledButtonText>
          </StyledButton>
        </StyledCard>
        <StyledCloseButton onClick={handleClose}>閉じる</StyledCloseButton>
      </StyledPopupContent>
    </StyledPopupWrapper>
  )
}

export default ShopEncouragementPopup

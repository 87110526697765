import React, { useEffect, useState } from 'react'

import { Radio } from '@nextui-org/react'
import { CreditCard } from '@torihada-inc/miniapps/dist/User'
import { Colors } from '@/constants/styles/color'
import { PURCHASE_METHOD } from '@/types/Purchase'
import PlusIcon from '@/assets/img/icons/Plus.svg'
import { cardTypeData } from '@/fanmeSdk/Payment/cardBrand'
import HalfModal from '@/components/atoms/Dialog/HalfModal'
import ARROW_RIGHT from '@/assets/img/icons/Arrow_Right.svg'
import HalfModalHeader from '@/components/molecules/Header/HalfModalHeader'
import { ga4DeletePaymentInfo } from '@/libs/ga_event'
import { PaymentType } from '@/types/GAEvents'
import ApplePayIcon from '@/assets/img/icons/Apple_Pay.svg'
import PayPayIcon from '@/assets/img/icons/PayPay.svg'
import CardSetup from '../CardSetup'
import FanmeSdkAlert from '../FanmeSdkAlert'
import {
  StyledAddCardButton,
  StyledDialogBody,
  StyledDialogBodyItem,
  StyledPurchaseTitle,
} from './index.style'

interface Props {
  onClose: () => void
  backTo: () => void
  toAddCardInfo: () => void
  toAddCvsInfo: () => void
  changePurchaseMethod: (method: string, cardId?: number) => void
  cardInfo: CreditCard[]
  activeCardInfo: CreditCard
  deleteCreditCard: (cardSequence: number) => void
  purchaseMethod?: string
  supportPurchaseMethods: string[]
  setUpdateCardInfo: (cardInfo: any) => void
  chooseCard?: boolean
}

type PaymentMethod = {
  name: string
  id: string
  action: () => void
}

const ChangePurchase = ({
  onClose,
  backTo,
  toAddCardInfo,
  toAddCvsInfo,
  changePurchaseMethod,
  cardInfo,
  activeCardInfo,
  deleteCreditCard,
  purchaseMethod,
  supportPurchaseMethods,
  setUpdateCardInfo,
  chooseCard,
}: Props) => {
  const [cardDeleteAlerVisible, setCardDeleteAlertVisible] = useState<boolean>(false)
  const [showCardSetup, setShowCardSetup] = useState<boolean>(false)

  // 使用するカードID
  const [activeCardId, setActiveCardId] = useState<number>()
  const [currentCardId, setCurrentCardId] = useState<number>()
  useEffect(() => {
    if (activeCardInfo) {
      setActiveCardId(activeCardInfo.cardId)
    }
  }, [activeCardInfo])
  // 支払い方法
  const [method, setMethod] = useState<string>()
  const [currentMethod, setCurrentMethod] = useState<string>()
  useEffect(() => {
    if (purchaseMethod) {
      setMethod(purchaseMethod)
    }
  }, [purchaseMethod])

  /**
   * 有効期限をYYMMからMM/YYにする
   */
  const convertCardExpiresAt = (cardExpiresAt: string) => {
    const cardExpiresAtArray = cardExpiresAt.match(/.{2}/g)
    cardExpiresAtArray && cardExpiresAtArray.reverse()
    return cardExpiresAtArray && cardExpiresAtArray.join('/')
  }

  const [cardBrandType, setCardBrandType] = useState<any>(null)
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardExpiresAt, setCardExpiresAt] = useState<string>('')
  const [cardSequence, setCardSequence] = useState<number>(0)
  const [paymentCardInfo, setPaymentCardInfo] = useState<CreditCard[]>(cardInfo)

  const onCardSetup = (cardType: any, cardData: any) => {
    setCardBrandType(cardType)
    setCardNumber(cardData?.cardNumber || '')
    setCardExpiresAt(`${convertCardExpiresAt(cardData?.cardExpiresAt || '')}`)
    setCardSequence(cardData?.cardId)
    setShowCardSetup(true)

    if (cardData?.cardId !== activeCardId) {
      setCurrentCardId(activeCardId)
      setActiveCardId(cardData?.cardId)
    }
    if (method !== PURCHASE_METHOD.CARD) {
      setMethod(PURCHASE_METHOD.CARD)
      setCurrentMethod(method)
    }
  }
  const onSetUseCard = () => {
    changePurchaseMethod(PURCHASE_METHOD.CARD, cardSequence)
    setShowCardSetup(false)
  }
  // カード更新
  const onUpdate = () => {
    const data = paymentCardInfo.find(card => card.cardId === activeCardId)
    setUpdateCardInfo(data)
    toAddCardInfo()
  }
  // カード削除
  const onDelete = () => {
    setShowCardSetup(false)
    setCardDeleteAlertVisible(true)

    ga4DeletePaymentInfo(PaymentType.CreditCard, [])
  }
  const onDeleteAction = () => {
    setCardDeleteAlertVisible(false)
    deleteCreditCard(cardSequence)
    setPaymentCardInfo(cardInfo.filter(card => card.cardId !== cardSequence))
  }
  const onCancel = () => {
    if (currentCardId !== undefined) {
      setActiveCardId(currentCardId)
      setCurrentCardId(undefined)
    }
    if (currentMethod !== undefined) {
      setMethod(currentMethod)
      setCurrentMethod(undefined)
    }
    setShowCardSetup(false)
  }

  const renderHeader = () => (
    <HalfModalHeader
      title="お支払い方法"
      background={Colors.WHITE}
      textColor={Colors.BLACK}
      onClose={onClose}
      onBack={backTo}
    />
  )

  const paymentMethods: PaymentMethod[] = [
    {
      id: PURCHASE_METHOD.CVS,
      name: 'コンビニ決済',
      action: toAddCvsInfo,
    },
    {
      id: PURCHASE_METHOD.GOOGLE_PAY,
      name: 'GooglePay',
      action: () => {
        changePurchaseMethod(PURCHASE_METHOD.GOOGLE_PAY)
      },
    },
    {
      id: PURCHASE_METHOD.APPLE_PAY,
      name: 'ApplePay',
      action: () => {
        changePurchaseMethod(PURCHASE_METHOD.APPLE_PAY)
      },
    },
    {
      id: PURCHASE_METHOD.PAY_PAY,
      name: 'PayPay',
      action: () => {
        changePurchaseMethod(PURCHASE_METHOD.PAY_PAY)
      },
    },
  ]

  const renderContent = () => (
    <StyledDialogBody>
      <StyledPurchaseTitle>クレジットカード</StyledPurchaseTitle>
      <Radio.Group value={`${method === PURCHASE_METHOD.CARD ? activeCardId : method}`}>
        {paymentCardInfo?.map((data, i) => {
          const targetData = data.cardName ? cardTypeData[data.cardName] : ''
          return (
            <div key={i}>
              <Radio
                key={`card${i}`}
                value={`${data.cardId}`}
                onClick={() => onCardSetup(targetData, data)}
                css={{
                  marginTop: '0 !important',
                  width: '100%',
                  borderBottom: `1px solid  ${Colors.VERY_LIGHT_GRAY}`,
                  '> span': {
                    width: '100%',
                  },
                  '.nextui-radio-point': {
                    m: '0',
                    '&::after': {
                      bg: `${Colors.WHITE}`,
                      border: `6px solid ${Colors.VERY_LIGHT_GRAY}`,
                      m: '0',
                    },
                  },
                  '.nextui-radio-active': {
                    '&::after': {
                      bg: `${Colors.WHITE}`,
                      border: `6px solid ${Colors.FANME_YELLOW} !important`,
                      m: '0',
                    },
                  },
                }}
              >
                <StyledDialogBodyItem paymentMethod={'card'}>
                  <div className="item-value">
                    <div className="card-info">
                      {targetData && targetData.icon}
                      <div className="detail">
                        <div className="numbers">
                          {`${targetData ? `${targetData.name} ` : ''}`}
                          {data.cardNumber}
                        </div>
                        <div className="date">
                          有効期限: {`${convertCardExpiresAt(data.cardExpiresAt || '')}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledDialogBodyItem>
                <ARROW_RIGHT width={16} height={16} />
              </Radio>
            </div>
          )
        })}

        <StyledAddCardButton onClick={toAddCardInfo}>
          <PlusIcon fill={Colors.BLACK} width={24} height={24} />
          クレジットカードを追加
        </StyledAddCardButton>

        {!chooseCard && supportPurchaseMethods.length > 1 && (
          <>
            <StyledPurchaseTitle>他の決済方法</StyledPurchaseTitle>
            {paymentMethods
              .filter(v => supportPurchaseMethods.indexOf(v.id) >= 0)
              .map((t, i) => (
                <Radio
                  key={`method-${t.id}`}
                  value={t.id}
                  onClick={t.action}
                  css={{
                    marginTop: '0 !important',
                    width: '100%',
                    minHeight: '56px',
                    justifyContent: 'center',
                    borderBottom: `1px solid  ${Colors.VERY_LIGHT_GRAY}`,
                    '> span': {
                      width: '100%',
                    },
                    '.nextui-radio-point': {
                      m: '0',
                      '&::after': {
                        bg: `${Colors.WHITE}`,
                        border: `6px solid ${Colors.VERY_LIGHT_GRAY}`,
                        m: '0',
                      },
                    },
                    '.nextui-radio-active': {
                      '&::after': {
                        bg: `${Colors.WHITE}`,
                        border: `6px solid ${Colors.FANME_YELLOW} !important`,
                        m: '0',
                      },
                    },
                  }}
                >
                  <StyledDialogBodyItem paymentMethod={t.name}>
                    <div className="item-value">
                      <div className="card-info">
                        <div className="detail">
                          <div className="title">
                            {t.name === 'GooglePay' ? (
                              <div>
                                <img
                                  src="/icon/google_pay.svg"
                                  alt="Google Pay"
                                  className="h-10 inline"
                                />
                                {t.name}
                              </div>
                            ) : t.name === 'ApplePay' ? (
                              <div className="flex items-center">
                                <ApplePayIcon className="inline-block mr-3" />
                                <div className="flex flex-col gap-1">
                                  <span>{t.name}</span>
                                  <span style={{ fontSize: '8px', color: Colors.GRAY }}>
                                    ※ Apple PayではJCBカードがご利用いただけません
                                  </span>
                                </div>
                              </div>
                            ) : t.name === 'PayPay' ? (
                              <div className="flex items-center">
                                <PayPayIcon />
                              </div>
                            ) : (
                              <div>{t.name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </StyledDialogBodyItem>
                </Radio>
              ))}
          </>
        )}
      </Radio.Group>
    </StyledDialogBody>
  )

  return (
    <>
      <CardSetup
        visible={showCardSetup}
        cardBrandType={cardBrandType}
        cardNumber={cardNumber}
        cardExpiresAt={cardExpiresAt}
        onSetUseCard={onSetUseCard}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onClose={onCancel}
      />
      <FanmeSdkAlert
        text={'カード情報を削除しますか？'}
        visible={cardDeleteAlerVisible}
        closeBtnText="閉じる"
        exclamationIconOn={true}
        action={'削除する'}
        onAction={onDeleteAction}
        onClose={() => {
          setCardDeleteAlertVisible(false)
        }}
      />
      <HalfModal header={renderHeader()} content={renderContent()} top={46}></HalfModal>
    </>
  )
}

export default ChangePurchase

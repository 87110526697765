import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { useAuth } from '@/contexts/AuthContext'
import { postBackend } from '@/libs/fanme_backend'
import AccountActivitiesDetail from '@/components/molecules/MyMenu/MoneyManagementDetail/AccountActivitiesDetail'
import MiniappTransactionDetail from '@/components/molecules/MyMenu/MoneyManagementDetail/MiniappTransactionDetail'
import ShopSalesHistoryDetail from '@/components/molecules/MyMenu/MoneyManagementDetail/ShopSalesHistoryDetail'
import {
  SellerHistoryAccountActivityResponse,
  SellerHistoryAccountTransactionResponseWithTip,
} from '@/types/MiniappSellerHistory'
import { SalesDetail } from '@/types/ShopSalesHistory'

interface Props {
  visible: boolean
  tableName: string
  tableId: number
  onClose: () => void
  shopSalesHistoryDetail?: SalesDetail
}

const { publicRuntimeConfig } = getConfig()

const MoneyManagementDetail = ({
  visible,
  tableName,
  tableId,
  onClose,
  shopSalesHistoryDetail,
}: Props) => {
  const { token } = useAuth()
  const [activityData, setActivityData] = useState<SellerHistoryAccountActivityResponse>()
  const [transactionData, setTransactionData] =
    useState<SellerHistoryAccountTransactionResponseWithTip[]>()
  const [currentItemDetail, setCurrenItemDetail] =
    useState<SellerHistoryAccountTransactionResponseWithTip>()
  const [goodsPrice, setGoodsPrice] = useState<number>(0)
  const [tipPrice, setTipPrice] = useState<number>(0)

  const calBenefit = (transactionData: SellerHistoryAccountTransactionResponseWithTip[]) => {
    let totalGoodsPrice = 0
    let totalTipPrice = 0
    transactionData.forEach(item => {
      if (!item.tip_id) {
        totalGoodsPrice += item.amount || 0
      } else {
        totalTipPrice += item.amount || 0
      }
    })
    setGoodsPrice(totalGoodsPrice)
    setTipPrice(totalTipPrice)
  }

  const getHistoryData = async (url: string, data: any) => {
    const historyData = await postBackend(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token?.toString()}`,
      },
    })
    const dataRes = historyData.data
    if (tableName === 'AccountActivities') {
      setActivityData(dataRes)
    } else {
      calBenefit(dataRes)
      setTransactionData(dataRes)
      setCurrenItemDetail(dataRes[0])
    }
  }
  useEffect(() => {
    const url =
      tableName === 'AccountActivities'
        ? `${publicRuntimeConfig.PAYMENT_URL}/seller/account/history/get/activity`
        : `${publicRuntimeConfig.PAYMENT_URL}/seller/account/history/get/transaction`

    const data =
      tableName === 'AccountActivities'
        ? { account_activity_id: tableId }
        : { transaction_id: tableId }

    if (tableName && tableId) {
      getHistoryData(url, data)
    }
  }, [tableName, tableId])

  const renderHeader = () => <FullModalHeader title="詳細履歴" onBack={onClose} />

  const renderContent = () => (
    <>
      {tableName === 'AccountActivities' && <AccountActivitiesDetail activityData={activityData} />}
      {tableName === 'Transactions' && transactionData && (
        <MiniappTransactionDetail
          transactionData={transactionData}
          currentItemDetail={currentItemDetail}
          goodsPrice={goodsPrice}
          tipPrice={tipPrice}
        />
      )}
      {tableName === 'ShopSalesHistory' && shopSalesHistoryDetail && (
        <ShopSalesHistoryDetail shopSalesHistoryDetail={shopSalesHistoryDetail} />
      )}
    </>
  )

  return (
    <FullModal
      header={renderHeader()}
      content={renderContent()}
      visible={visible}
      zIndex={10000}
      bodyPadding="0 16px"
    />
  )
}

export default MoneyManagementDetail

import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

export const StyledMiniAppEndNoticeContainer = styled.a`
  width: 100%;
  height: 40px;
  max-width: 338px;
  background: ${Colors.RED_14};
  border: 1px solid ${Colors.RED_50};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${Colors.RED};
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-decoration: none;
`

export const StyledMiniAppEndNoticeContent = styled.div`
  display: flex;
  align-items: center;
`

import React, { useState } from 'react'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import Tabs from '@/components/atoms/Tabs'
import { SalesDetail } from '@/types/ShopSalesHistory'
import ShopMoneyManagementHistory from '@/components/molecules/MyMenu/MoneyManagementHistory/ShopMoneyManagementHIstory'
import MiniappMoneyManagementHistory from '@/components/molecules/MyMenu/MoneyManagementHistory/MiniappMoneyManagementHistory'
import MoneyManagementDetail from '../MoneyManagementDetail'

interface Props {
  accountId: string
  visibleSalesBalanceHistory: boolean
  onClose: () => void
}
const MoneyManagementHistory = ({ accountId, visibleSalesBalanceHistory, onClose }: Props) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [visibleDetail, setVisibleDetails] = useState(false)
  const [tableName, setTableName] = useState<any>('')
  const [tableId, setTableId] = useState<any>('')
  const [shopSalesHistoryDetail, setShopSalesHistoryDetail] = useState<SalesDetail>()

  const renderHeader = () => <FullModalHeader title="売上金明細" onBack={onClose} />

  const renderContent = () => (
    <>
      <Tabs
        current={tabIndex}
        tabs={['ショップ', '旧ショップ']}
        onChange={index => setTabIndex(index)}
      />
      {tabIndex === 0 ? (
        <ShopMoneyManagementHistory
          setTableName={setTableName}
          setVisibleDetails={setVisibleDetails}
          setTableId={setTableId}
          setShopSalesHistoryDetail={setShopSalesHistoryDetail}
        />
      ) : (
        <MiniappMoneyManagementHistory
          accountId={accountId}
          visibleSalesBalanceHistory={visibleSalesBalanceHistory}
          setTableName={setTableName}
          setVisibleDetails={setVisibleDetails}
          setTableId={setTableId}
        />
      )}
    </>
  )

  return (
    <>
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visibleSalesBalanceHistory}
        zIndex={10000}
        bodyPadding="16px"
      />
      <MoneyManagementDetail
        visible={visibleDetail}
        tableName={tableName}
        tableId={tableId}
        onClose={() => setVisibleDetails(false)}
        shopSalesHistoryDetail={shopSalesHistoryDetail}
      />
    </>
  )
}

export default MoneyManagementHistory

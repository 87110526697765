import React from 'react'

import getConfig from 'next/config'
import Shop from '@/assets/img/icons/Shop.svg'
import { event } from '@/pages/api/gtag'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

const { publicRuntimeConfig } = getConfig()

const goShopPage = () => {
  event({
    action: 'fanme_shop_click',
    category: 'fanme_shop_click',
    label: 'fanme_shop_click',
    value: 'fanme_shop_click',
  })
  window.open(publicRuntimeConfig.SHOP_URL, '_blank')
}

const ShopPage = () => {
  return (
    <StyledMyMenuItem onClick={goShopPage} width="100%">
      <Shop width={20} height={20} />
      <StyledMyMenuItemName>ショップ</StyledMyMenuItemName>
      <div className="absolute top-2 right-4 text-PINK text-size12">NEW!</div>
    </StyledMyMenuItem>
  )
}

export default ShopPage

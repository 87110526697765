import React from 'react'
import ToggleSwitch from '@/components/atoms/ToggleSwitch'

interface EnabledPushNotificationProps {
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
  enabled: boolean
}

const EnablePushNotification = ({ setEnabled, enabled }: EnabledPushNotificationProps) => {
  const handleChange = async () => {
    if (enabled) {
      setEnabled(false)
    } else {
      if (typeof Notification !== 'undefined') {
        await Notification.requestPermission()
      }
      setEnabled(true)
    }
  }

  return (
    <div className="p-4">
      <div className="text-[15px] text-fanme-black text-left pb-4">通知設定</div>
      <div className="flex justify-between">
        <div className="flex flex-col items-start">
          <div className="text-[13px] text-fanme-black">プッシュ通知を許可する</div>
          <div className="text-xs text-[#999999] text-left">
            ※iPhoneまたはiPadをお使いの場合は、
            <br />
            FANMEを
            <span className="text-[#FF623B]">ホーム画面に追加する</span>
            必要があります。
          </div>
        </div>
        <ToggleSwitch checked={enabled} onChange={handleChange} size="sm" />
      </div>
    </div>
  )
}

export default EnablePushNotification
